<template>
  <div style="padding-bottom: 48px">
    <BaseForm
      ref="form"
      :disabled="disabled"
      :form-type="formType"
      :layout="layout"
      :loading="loading"
      :row="row"
      :select-models="selectModels"
    >
      <template #bottom>
        <div class="button-container">
          <v-btn
            id="change-password-form-button-change-password"
            :disabled="loading"
            :loading="loading"
            class="btn-primaryaction"
            @click="submit"
          >
            Change Time Zone
          </v-btn>
        </div>
      </template>
    </BaseForm>
  </div>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue'
import { authComputed } from '@/state/helpers'

export default {
  components: {
    BaseForm,
  },
  data() {
    return {
      formType: 'Change User Time Zone',
      row: {},
      selectModels: {},
      loading: false,
      layout: [],
      errorMessage: '',
      disabled: false,
      displayErr: false,
      userTimeZone: null,
      timezoneOptions: [
        {
          id: 0,
          text: 'Eastern Time',
          zoneName: 'America/New_York',
        },
        {
          id: 1,
          text: 'Central Time',
          zoneName: 'America/Chicago',
        },
        {
          id: 2,
          text: 'Mountain Time',
          zoneName: 'America/Denver',
        },
        {
          id: 3,
          text: 'Pacific Time',
          zoneName: 'America/Los_Angeles',
        },
        {
          id: 4,
          text: 'Alaskan Time',
          zoneName: 'America/Anchorage',
        },
        {
          id: 5,
          text: 'Hawaiian Time',
          zoneName: 'Pacific/Honolulu',
        },
      ],
    }
  },
  computed: {
    ...authComputed,
  },
  mounted() {
    this.userTimeZone = this.timezoneOptions.find(
      (tz) =>
        tz.zoneName ==
        (this.currentUser.timeZone || this.currentUser.company.address.timeZone)
    )

    this.selectModels.selectedTimeZone = this.userTimeZone

    this.layout = [
      {
        id: 1,
        fields: [
          {
            key: 'timeZone',
            label: 'User Time Zone',
            type: 'select',
            modelName: 'selectedTimeZone',
            model: this.userTimeZone,
            items: this.timezoneOptions,
          },
        ],
      },
    ]
  },
  methods: {
    displayErrMsg(e, status) {
      this.displayErr = status
      this.errorMessage = e
    },
    async submit() {
      this.loading = true

      await this.$store
        .dispatch('users/updateUserTimezone', {
          id: this.currentUser.userId,
          payload: { timeZone: this.selectModels.selectedTimeZone.zoneName },
        })
        .then(() => {
          this.$store.dispatch('app/showAlert', {
            message: 'Successfuly changed time zone.',
          })
        })
        .catch((e) => {
          this.$store.dispatch('app/showAlert', {
            message:
              'Something went wrong with the request - please try again later.',
            type: 'error',
          })
        })

      this.loading = false
      this.$store.dispatch('auth/getCurrentUser')
    },
  },
}
</script>

<style lang="scss" scoped>
.form-error {
  position: fixed;
  left: 12%;
  color: $red;
}

.button-container {
  position: relative;
  right: 1.3%;
  display: flex;
  flex-direction: row;
}

::v-deep .v-card__title {
  padding: 16px 16px 16px 0;
}

::v-deep input:disabled {
  background-color: $blue-dull;
}

::v-deep .card {
  position: relative;
  flex-direction: column;
  padding: 24px;
  background: $white;
  border-radius: 15px;
}

::v-deep .sheet {
  padding: 0 70px 30px 70px;
  margin-bottom: 0;
  border-radius: 0 0 15px 15px;
}

::v-deep .container {
  padding: 0 24px 0 24px;
}

::v-deep label.v-label.theme--light {
  width: 140px;
}

::v-deep .row-block {
  max-width: 45%;
}

::v-deep textarea {
  overflow-y: scroll;
  resize: 0;
}
</style>
